import BotResponse from "./BotResponse";

const IntroSection = () => {
  return (
    <div id="introsection">
      <h1>
        Conheça o José Bot
        <BotResponse response=" - Seu Assistente Virtual Inteligente" />
      </h1>
      <h2>
        Uma plataforma avançada com tecnologia de inteligência artificial pronta para fornecer respostas imediatas para suas dúvidas. Com o José Bot, você tem acesso a informações e assistência a qualquer hora, para qualquer necessidade, seja para estudos, trabalho ou curiosidades gerais.
      </h2>
      Recursos e habilidades do José Bot:
      <ul>
        <li>Respostas rápidas a perguntas diversas</li>
        {/* <li>Tecnologia de aprendizado de máquina que se aprimora com o uso</li> */}
        {/* <li>Capacidade de aprendizado contínuo e personalização de interações</li> */}
        <li>Interface amigável e fácil de usar</li>
        {/* <li>Auxílio na organização de tarefas e lembretes</li> */}
        {/* <li>Recomendações personalizadas baseadas em preferências e comportamento</li> */}
        <li>Pesquisa e sumarização de conteúdos e notícias relevantes</li>
        <li>Conversão de texto em mensagens de áudio</li>
        <li>Busca de endereços e informações geográficas</li>
        <li>Geração de imagens a partir de descrições textuais usando IA</li>
      </ul>
      <p>
        Esqueça as buscas exaustivas e a digitação sem fim. Dê as boas-vindas ao José Bot, seu assistente virtual inteligente. Experimente agora e descubra como o José Bot pode tornar seu cotidiano mais fácil e eficiente.
      </p>
    </div>
  );
};

export default IntroSection;