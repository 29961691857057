import { Link } from "react-router-dom";
import { useSessionStore } from "../store/SessionStore";

const NavLinks = ({ svg, link, text, setChatLog }) => {
   
  const handleLogout = useSessionStore(state => state.logout);

  const handleClick = async (text) => {
    // if (text === "Clear Conversations") setChatLog([]);
    if (text === "Log out") {
      try {
        handleLogout();
      } catch (error) {
        console.error("error happen during sign out", error);
      }
    }
  };

  return (
    <Link
      to={link}
      target={link && "_blank"}
      rel="noreferrer"
      style={{ textDecoration: "none" }}
      onClick={() => handleClick(text)}
    >
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavLinks;
