import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import SvgComponent from "../components/SvgComponent";
import SignupForm from "../components/signup/SignUpForm";
import { useSessionStore } from "../store/SessionStore";

const Login = () => {
  const [isAuthenticated, setSession] = useSessionStore(state => [state.isAuthenticated, state.setSession]);
  const [isSignupFormVisible, setIsSignupFormVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
      return;
    }

    const parseAccessToken = () => {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.substring(1)); // Remove o '#'
      const accessToken = params.get('access_token');
      if (accessToken) {
        setSession(Object.fromEntries(params.entries()));
        navigate('/');
      }
    };

    parseAccessToken();
  }, [navigate, isAuthenticated, setSession]);

  const handleClick = async (purpose) => {
    if (purpose === "signup") {
      setIsSignupFormVisible(true);
    }
    if (purpose === "login") {
      window.location.href = process.env.REACT_APP_AUTHENTICATION_API + '/login/google?response_type=token&redirect_uri=' + process.env.REACT_APP_SITE_URL + '/auth/login';
    }
  };

  return (
    <>
      {!isSignupFormVisible ? (
        <div className="loginContainer">
          <div className="loginContainerContent">
            <SvgComponent w={50} h={50} />
            <h1>Bem-vindo ao José Bot</h1>
            <p>Seu Assistente Pessoal com Inteligência Artificial</p>
            <div className="loginButtonWrapper">
              <Button text="Log in" handleClick={() => handleClick("login")} />
              <Button
                text="Sign up"
                handleClick={() => handleClick("signup")}
              />
            </div>
          </div>
        </div>
      ) : (
        <SignupForm />
      )}
    </>
  );
};

export default Login;
