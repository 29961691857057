import { create } from 'zustand';
import { jwtDecode } from 'jwt-decode';

const USER_KEY = 'user';
const TOKEN_KEY = 'token';
const SESSION_KEY = 'session';

export const useSessionStore = create((set) => ({
    token: localStorage.getItem(TOKEN_KEY) || null,
    session: JSON.parse(localStorage.getItem(SESSION_KEY)) || null,
    currentUser: JSON.parse(localStorage.getItem(USER_KEY)) || null,
    isAuthenticated: Boolean(localStorage.getItem(TOKEN_KEY) || false),

    logout: () => {
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(SESSION_KEY);
        set({ currentUser: null, token: null, session: null, isAuthenticated: false });
    },

    setSession: (session) => {
        const token = session.access_token;
        const userData = jwtDecode(token);
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(USER_KEY, JSON.stringify(userData));
        localStorage.setItem(SESSION_KEY, JSON.stringify(session));
        set({ session, token, currentUser: userData, isAuthenticated: true });
    },
}));
